<template>
  <div class="about">
    <div class="font-maxime text-4xl tracking-widest text-white text-center">
      {{ $t('bio.title') }}
    </div>
    <div class="font-maxime text-2xl tracking-widest p-3">
      <div
        v-for="i in 8"
        :key="i"
      >
        <img
          v-if="i === 1"
          class="mt-8 m-4 float-right mt-2 h-48 w-full object-cover md:h-full md:w-64 rounded-xl"
          src="../assets/bio.png"
          alt="Biography"
        >
        <p class="text-white pt-8">
          {{ $t(`bio.p${i}`) }}
        </p>
        <img
          v-if="i === 4"
          class="mt-8 m-4 float-right mt-2 h-48 w-full object-cover md:h-full md:w-64 rounded-xl"
          src="../assets/bio2.jpg"
          alt="Profile"
        >
      </div>
      <div class="flex justify-center mt-8">
        <img
          class="h-24"
          src="../assets/sign.png"
          alt="LV signature"
        >
      </div>
    </div>
  </div>
</template>

<script>



export default {
  computed: {
  },
}
</script>
